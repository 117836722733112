import React from 'react'
import './Home.css'
import john1 from '../../Images/john1.png';
import bigSquat from '../../Images/bigSquat.png';

const Home = () => {
    return (
        <div>
            <img src={john1} className="header-img" alt="john1"/>
            <div className="header-content">
                <i className="header-text">You know you wanna look like me...</i>
                <p className="header-text">LET ME MAKE YOU LOOK LIKE ME</p>
                <a href="/coaching"><button className='button-1'>COACHING</button></a>
            </div>
            <div className="home-body">
                <h1 className="home-body-text">Me JOHN make you big</h1>
                <h3 className="home-body-text">
                    Hey I'm John, my favorite pastimes are lifting weights for 4 hours/day, raving, and masturbating.  
                    If you're tired of not having a roman statue bod, then let me show help you out.  I've got drugs, 
                    and will make fun of you if you don't achieve your goals.
                </h3>
            </div>
            <a href="/coaching" className="banner-button-link">
                <img src={bigSquat} className="banner-button-img" alt="cocktail-banner"/>
                <h1 className="banner-button-text">WHAT ARE YOUR GOALS?</h1>
                <h3 className="banner-button-text">View Coaching Types Here</h3>
            </a>
        </div>
    )
}

export default Home
