import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Goals.css'
import emailjs from '@emailjs/browser'
import jc3 from '../../Images/jc3.jpg';

const Goals = () => {
  const form = useRef();

  const [loading, setLoading] = useState(false)

  const submit = e => {
    e.preventDefault()
    setLoading(true)
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm('service_yt6q5b9', 'template_r8k6zg8', form.current, '9jn9S_al4iCuOsQ7d')
      .then((result) => {
          setLoading(false)
          toast("Success! We sent your goals to John, he'll check them out ASAP.")
          console.log(result.text);
        }, (error) => {
          setLoading(false)
          toast("Network Error: goals not submitted. Book a call with John and he'll get back to you ASAP.")
          console.log(error.text);
      });
  }

    return (
      <div>
        <ToastContainer />
        <img src={jc3} className="goals-header-img" alt="jc3"/>
        <div className="goals-body">
          <h1>What Are Your Goals?</h1>
          <form className="goals-form column" ref={form} onSubmit={submit}>
            <label className="goals-label bold column">Name
              <input className="form-input" type="text" name="name" required/>
            </label>
            <div className="goals">
              <div className="column1">
                <div className="goals-label main-goals-label bold">What are your main goals?</div>
                <label><input className="checkbox goal-type" type="checkbox" value="lose weight" name="lose-weight"/>Lose Weight</label>
                <label><input className="checkbox goal-type" type="checkbox" value="build muscle" name="build-muscle"/>Build Muscle</label>
              </div>
              <div className="column2">
                <label><input className="checkbox goal-type" type="checkbox" value="competitive powerlifting" name="powerlifting"/>Competitive Powerlifting</label>
                <label><input className="checkbox goal-type" type="checkbox" value="general health" name="general-health"/>General Health</label>
              </div>
            </div>
            <label className="goals-label bold column">Availability
              <div className="availability">
                <div className="day-label">Monday</div>
                <div className="availability-day">       
                  <label><input className="checkbox" type="checkbox" value="morning" name="monday-morning"/>morning</label>
                  <label><input className="checkbox" type="checkbox" value="afternoon" name="monday-afternoon"/>afternoon</label>
                  <label><input className="checkbox" type="checkbox" value="evening" name="monday-evening"/>evening</label>
                </div>
                <div className="day-label">Tuesday</div>
                <div className="availability-day">
                  <label><input type="checkbox" value="morning" name="tuesday-morning"/>morning</label>
                  <label><input type="checkbox" value="afternoon" name="tuesday-afternoon"/>afternoon</label>
                  <label><input type="checkbox" value="evening" name="tuesday-evening"/>evening</label>
                </div>
                <div className="day-label">Wednesday</div>
                <div className="availability-day">
                  <label><input type="checkbox" value="morning" name="wednesday-morning"/>morning</label>
                  <label><input type="checkbox" value="afternoon" name="wednesday-afternoon"/>afternoon</label>
                  <label><input type="checkbox" value="evening" name="wednesday-evening"/>evening</label>
                </div>
                <div className="day-label">Thursday</div>
                <div className="availability-day">
                  <label><input type="checkbox" value="morning" name="thursday-morning"/>morning</label>
                  <label><input type="checkbox" value="afternoon" name="thursday-afternoon"/>afternoon</label>
                  <label><input type="checkbox" value="evening" name="thursday-evening"/>evening</label>
                </div>
                <div className="day-label">Friday</div>
                <div className="availability-day">
                  <label><input type="checkbox" value="morning" name="friday-morning"/>morning</label>
                  <label><input type="checkbox" value="afternoon" name="friday-afternoon"/>afternoon</label>
                  <label><input type="checkbox" value="evening" name="friday-evening"/>evening</label>
                </div>
                <div className="day-label">Saturday</div>
                <div className="availability-day">
                  <label><input type="checkbox" value="morning" name="saturday-morning"/>morning</label>
                  <label><input type="checkbox" value="afternoon" name="saturday-afternoon"/>afternoon</label>
                  <label><input type="checkbox" value="evening" name="saturday-evening"/>evening</label>
                </div>
              </div>
            </label>
            <label className="goals-label bold column">Training Type
              <select className="training-type" name="training-type">
                <option value="in person">In Person</option>
                <option value="online">Online</option>
              </select>
            </label>
            <label className="goals-label bold column">Injuries, limitations, things I should know
              <textarea className="misc" name="misc" />
            </label>
            <div className="contact-fields">
              <div className="contact-inputs">
                <label className="goals-label bold">Email</label>
                <input className="form-input contact" type="email" name="email" required/>
                <label className="goals-label bold">Phone Number</label>
                <input className="form-input contact" type="text" name="phone" required/>
              </div>
              <div className="primary-contact">
                <label className="goals-label bold" style={{ "marginBottom": "15px" }}>Preferred</label>
                <input type="checkbox" value="preferred" name="email-preferred" style={{ "marginBottom": "67px" }}/>
                <input type="checkbox" value="preferred" name="phone-preferred"/>
              </div>
            </div>
            
            <button className="button-1 goals-submit" type="submit">
              {loading ? <div className="loading-spinner"></div> : <div>SUBMIT</div>}
            </button>
          </form>
        </div>
      </div>
    )
}

export default Goals
