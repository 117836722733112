import React from 'react'
import './App.css';
import Nav from './Components/Nav/Nav'
import Routes from './Routes'
import Footer from './Components/Footer/Footer'
import { PopupWidget } from "react-calendly"

function App() {

  return (
    <div className="App">
        <Nav />
        <PopupWidget
          url="https://calendly.com/alpinebarbellutah/30min"
          rootElement={document.getElementById("root")}
          text="Book a call with me"
          textColor="black"
          color="#A2D6A2"
        />
        <div className="Routes">{Routes}</div>
        <Footer />
    </div>
  );
}

export default App;
