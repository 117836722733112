import React, { useState, useRef, useEffect } from 'react'
import './Nav.css'
import barbell from '../../Images/barbell.jpg';
import menuButton from '../../Images/menuButton.jpg';

const CloseMenuOnOutsideClick = ({ref, menuOpen, toggleMenu}) => {
  useEffect(() => {
    const handleOutisdeClick = e => {
      if (menuOpen &&
          ref.current &&
          ref.current !== e.target &&
          e.target.className !== 'hamburger') {
        toggleMenu(false)
      }
    }
    document.addEventListener('click', handleOutisdeClick)
    return () => document.removeEventListener('click', handleOutisdeClick)
  })
}

const Nav = () => {
  const [menuOpen, toggleMenu] = useState(false)
  const mobileMenu = useRef(null);
  CloseMenuOnOutsideClick({
    ref:mobileMenu,
    menuOpen,
    toggleMenu
  })

  return (
      <div className="nav">
        <div>
          <img src={barbell} className="logo" alt="logo" />
          <a className="company-name" href="/">Alpine<br></br>Barbell</a>
        </div>

        <div className="nav-menu">
          <a className="nav-menu-link" href="/">Home</a>
          <a className="nav-menu-link" href="/goals">Fitness Goals</a>
          {/* <a className="nav-menu-link" href="/about">About</a> */}
          <a className="nav-menu-link" href="/waiver">Waiver</a>
          <a className="nav-menu-link" href="/coaching">Coaching</a>
        </div>

        <button className="menu-button" onClick={()=>toggleMenu(!menuOpen)}><img className="hamburger" src={menuButton} alt="menu-button" /></button>
        {menuOpen ? (
          <div className="mobile-menu" ref={mobileMenu}>
            <a className="nav-menu-link mobile" href="/">Home</a>
            <a className="nav-menu-link mobile" href="/goals">Goals</a>
            {/* <a className="nav-menu-link mobile" href="/about">About</a> */}
            <a className="nav-menu-link mobile" href="/waiver">Waiver</a>
            <a className="nav-menu-link mobile" href="/coaching">Coaching</a>
          </div>
        ):null}
      </div>
  )
}

export default Nav