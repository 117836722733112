import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Home from './Components/Home/Home'
import Goals from './Components/Goals/Goals'
import About from './Components/About/About'
import Waiver from './Components/Waiver/Waiver'
import Coaching from './Components/Coaching/Coaching'
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'

export default (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/goals' element={<Goals />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/waiver' element={<Waiver />}/>
            <Route path='/coaching' element={<Coaching />}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
        </Routes>
    </BrowserRouter>
)