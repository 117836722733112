import React, { useState } from 'react'
import './Coaching.css'
import alleyway from '../../Images/alleyway.png';


const Coaching = () => {
    const [descOneOpen, setDescOneOpen] = useState(false)
    const [descTwoOpen, setDescTwoOpen] = useState(false)
    
    return (
      <div>
        <img src={alleyway} className="coaching-header-img" alt="alleyway"/>
        <div className="body">
          <h1>Coaching Types</h1>
          <div className="packageDescriptions">
            <button
              onClick={()=>setDescOneOpen(!descOneOpen)}
              className="descriptionButton"  
            >
              In Person Coaching
              <div>&darr;</div>
            </button>
            {descOneOpen ? (
              <div className="packageDescription">
                <p>-In person coaching available M T TH F 9:30am - 1:30pm</p>
                <p>-After hours coaching upon request</p>
              </div>
            ):null}
            
            <button
              onClick={()=>setDescTwoOpen(!descTwoOpen)}
              className="descriptionButton"  
            >
              Online Only Coaching
              <div>&darr;</div>
            </button>
            {descTwoOpen ? (
              <div className="packageDescription">
                <p>-Weekly updates based off feedback</p>
                <p>-Additional Coaching:</p>
                  <ul>
                    <li>Nutrition and meal planning</li>
                    <li>1 hr online consultations</li>
                  </ul>
              </div>
            ):null}
            
            
          </div>
        </div>
        <h1>What are your goals?</h1>
        <p>You can book a call with me using the button on the bottom right of the screen, or <a href="/goals">tell me about your goals here </a> and I'll get back to you asap!</p>
        <a href="/goals"><button className='button-1'>GOALS</button></a>
      </div>
    )
}

export default Coaching
